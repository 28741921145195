<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <b-form-group
              :label="$t('labels.title')"
              label-for='name'
            >
              <b-form-input
                id='title'
                :value='searchFields.title'
                @input="inputChange($event, 'title')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <label>{{ $t('labels.status') }}</label>
            <v-select
              v-model='searchFields.status'
              :dir='$store.state.appConfig.layout.direction'
              :options='statuses'
              :reduce='item => item.id'
              label='name'
              class='w-100'
            />
          </b-col>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <b-form-group :label="$t('labels.keywords')">
              <b-form-tags
                v-model='searchFields.keywords'
                input-id='tags-pills'
                tag-variant='primary'
                tag-pills
                size='md'
                separator=' '
                :placeholder="$t('labels.keywords')"
                @input="inputChange($event, 'keywords')"
                :add-button-text="$t('labels.add')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <DatePicker
              :label="$t('labels.created_on')"
              :reset='true'
              v-model='created_at'
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name='resourceName'
      :singular-name='singularName'
      :filter-fields='searchFields'
      :can-change-status='false'
      :permissionKey='"blog"'
    >
<!--      <template v-slot:dropdown_actions='{ item, generalActions }'>-->
<!--        &lt;!&ndash;        <b-dropdown-item&ndash;&gt;-->
<!--        &lt;!&ndash;          :to="{ name: 'news-ratings', params: { blog_id: item.id } }"&ndash;&gt;-->
<!--        &lt;!&ndash;        >&ndash;&gt;-->
<!--        &lt;!&ndash;          <feather-icon&ndash;&gt;-->
<!--        &lt;!&ndash;            icon='BarChartIcon'&ndash;&gt;-->
<!--        &lt;!&ndash;            class='mr-50'&ndash;&gt;-->
<!--        &lt;!&ndash;          />&ndash;&gt;-->
<!--        &lt;!&ndash;          <span>{{ $t('News Ratings') }}</span>&ndash;&gt;-->
<!--        &lt;!&ndash;        </b-dropdown-item>&ndash;&gt;-->
<!--        <b-dropdown-item-->
<!--          :to="{ name: 'news-views', params: { blog_id: item.id } }"-->
<!--        >-->
<!--          <feather-icon-->
<!--            icon='EyeIcon'-->
<!--            class='mr-50'-->
<!--          />-->
<!--          <span>{{ $t('News Views') }}</span>-->
<!--        </b-dropdown-item>-->
<!--      </template>-->
    </TableIndex>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'blogs',
      singularName: 'blog',
      searchFields: {
        title: null,
        status: null,
        created_at: null,
        keywords: [],
      },
      created_at: null,
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function(e, field) {
      this.searchFields[field] = e
    }, 500),
  },

  watch: {
    'created_at': {
      handler(val) {
        if (this.searchFields['created_at'] == null && (val == '' || val == null)) {
          return true
        }

        this.searchFields['created_at'] = val
      },
    },
  },


  // eslint-disable-next-line vue/order-in-components
  computed: {
    statuses() {
      return [
        {
          id: '0',
          name: this.$t('statuses.0'),
        },
        {
          id: '1',
          name: this.$t('statuses.1'),
        },
        {
          id: '2',
          name: this.$t('statuses.2'),
        },
      ]
    },
  },
}
</script>
